import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { SelectMarketplace } from '@junglescout/edna'

import { MARKETPLACES_IN_TRANSITION } from 'constants/countries'

const MarketplaceInput = ({ values, setValues }) => {
  const [open, setOpen] = useState(false)

  const value = values.country
  const setValue = country => {
    setOpen(false)
    setValues({ ...values, country })
  }

  return (
    <SelectMarketplace
      open={open}
      onOpenChange={setOpen}
      placeholder="Select an option"
      width="200px"
      selectedMarketplace={value}
      onMarketplaceChange={setValue}
      marketplaces={Object.values(MARKETPLACES_IN_TRANSITION)}
    />
  )
}

MarketplaceInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired
}

export { MarketplaceInput }
