import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

import { useAIAssistCalendarDates } from 'hooks/calendar'
import { CalendarDropdown } from 'ui_elements/CalendarDropdown/CalendarDropdown'

const Container = styled.div`
  [class*='DropdownTrigger'] {
    width: ${isMobile ? 'auto' : '360px'};
    margin-left: 0 !important;
    width: ${isMobile ? 'unset' : '360px'};
  }
`

const DateRangeInput = ({ values, setValues, isComparison }) => {
  const startDateKey = isComparison ? 'comparison_start_date' : 'start_date'
  const endDateKey = isComparison ? 'comparison_end_date' : 'end_date'

  const startDate = values[startDateKey]
  const endDate = values[endDateKey]

  const { calendarData, setDates } = useAIAssistCalendarDates({
    startDate,
    endDate,
    rangeType: values.dateRangeType
  })

  const formattedDates = calendarData?.formattedDates || {}
  const selectedStartDate = formattedDates?.startDate
  const selectedEndDate = formattedDates?.endDate

  useEffect(() => {
    if (!selectedStartDate || !selectedEndDate) return
    setValues({
      ...values,
      [startDateKey]: selectedStartDate,
      [endDateKey]: selectedEndDate,
      dateRangeType: calendarData?.selectedRange?.rangeType
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate])

  return (
    <Container>
      <CalendarDropdown
        calendarData={calendarData}
        setDates={setDates}
        trackingLocation="AI Assist"
      />
    </Container>
  )
}

DateRangeInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  isComparison: PropTypes.bool,
  setValues: PropTypes.func.isRequired
}

DateRangeInput.defaultProps = {
  isComparison: false
}

export { DateRangeInput }
